import Api from '@/resources/Api'
import defaults, { buildEndpointUrl } from './utils'
import CryptoJS from 'crypto-js'
import { reactive } from 'vue'

const generateKey = (obj) => CryptoJS.MD5(JSON.stringify(obj)).toString()

const LIMIT_VALUE = 25

const baseState = () => ({
    items: {}, // Will hold the Objects
    mapId: {}, // Maps the $id to the id
    inboxes: {}, // Contains the properties for each key (filter based on search+order)
    viewedInboxes: [], // Ordered set of added inboxes in order to delete the oldest one when reaching a size of 3
    currentKey: null, // Current Inbox generated Key
    current: {}, // The currently filtered list of objects
    filtering: false, // When filtering, we need a way to prevent the function from being called multiples times at once
    viewers: {} // Maps the account_id => conversation_id currently viewing. Basic dict {id:id}
})

const conversations = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        endpointUrl: () => (params) => buildEndpointUrl('conversations/', 'id', params),
        currentKey: (state) => state.currentKey,
        current: (state) => state.currentKey in state.inboxes ? state.inboxes[state.currentKey] : null,
        getItems: (state) => (key) => key in state.inboxes ? state.inboxes[key].items : null,
        filtered: (state, getters) => {
            if (!getters.current) return []
            return getters.current.items.map(id => state.items[id]).filter(inst => inst !== null)
        },
        isLoading: (state, getters) => getters.current?.$loading,
        hasMore: (state, getters) => getters.current?.hasNext,
        getPrev: (state, getters) => (id) => {
            if (!getters.current?.shallowItems?.length) return null

            const index = getters.current?.shallowItems.indexOf(id) - 1
            if (index < 0) return null
            return getters.current?.shallowItems[index]
        },
        getNext: (state, getters) => (id, store) => {
            if (!getters.current?.shallowItems?.length) return null

            const index = getters.current?.shallowItems.indexOf(id) + 1
            if (index === 0) return null // The id wasn't found (indexOf => -1, + 1 = 0; quik mafs)

            if (store && index + 2 >= getters.current?.shallowItems.length) { // +2 because +1 to match 1-based array, then + 1 to anticipate one in advance
                store.dispatch('conversations/next')
            }
            if (index >= getters.current?.shallowItems.length) return null
            return getters.current?.shallowItems[index]
        },
        getFirst: (state, getters) => {
            if (!(getters.current?.shallowItems?.length > 0)) return null
            return getters.current?.shallowItems[0]
        }
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        },
        ADD_ENTRY: (state, { targetId, document, getters }) => {
            document._tags = reactive(document.tags || [])
            document._recipients = reactive(document.recipients || [])
            document.viewers = document.viewers || []
            for (const k in state.viewers) {
                if (state.viewers[k] === targetId) {
                    // An agent is currently viewing it!
                    document.viewers.append(k)
                }
            }

            Object.defineProperties(document, {
                agent: {
                    get () {
                        return getters['agents/get'](this.agent_id)
                    }
                },
                contact: {
                    get () {
                        return getters['contacts/get'](this.contact_id)
                    }
                },
                messages: {
                    get () {
                        return getters['messages/conversation'](this.id)
                    }
                },
                tags: {
                    get () {
                        // Some tags might not be fully loaded yet
                        return document._tags.map(id => getters['tags/get'](id)).filter(x => x !== null)
                    }
                },
                recipients: {
                    get () {
                        return document._recipients.map(rcpt => ({
                            id: rcpt.id,
                            kind: rcpt.from,
                            contact: getters['contacts/get'](rcpt.contact_id)
                        })).filter(rcpt => rcpt.contact !== null)
                    }
                }
            })

            if (!('channel' in document)) {
                // Might be already present in the onboarding,
                // That's why we test before adding it
                Object.defineProperty(document, 'channel', {
                    get () {
                        return getters['channels/get'](this.channel_id)
                    }
                })
            }

            state.items[targetId] = document
        },
        CREATE_INBOX_STATE (state, { key, props }) {
            if (!(key in state.inboxes)) {
                state.inboxes[key] = {
                    ...props,
                    $loading: true, // Yes, we set at true because it's a new set, so it will load
                    $running: false, // Used to track when the loading is done
                    pending: [],
                    items: [],
                    shallowItems: [],
                    news: [], // news is just a list of new items that will be removed once the timeout expires, for CSS effects
                    removeds: {} // removeds is a dict of id:timeout-id
                }
            }
        },
        SET_CURRENT (state, props) {
            state.currentKey = props.key
            if (!(state.currentKey in state.inboxes)) {
                this.commit('conversations/CREATE_INBOX_STATE', { key: state.currentKey, props })
            }

            state.inboxes[state.currentKey].hasNext = true // Since we load an inbox, we refresh the set by forcing the fetch
            state.inboxes[state.currentKey].cursor = null // When loading a new inbox, we always reset the cursor

            if (state.inboxes[state.currentKey].items.length > 250) {
                // In case we have more than 250 items, we remove the next ones because the API doesn't accept more than 250
                state.inboxes[state.currentKey].items = state.inboxes[state.currentKey].items.slice(0, 250)
                state.inboxes[state.currentKey].shallowItems = [...state.inboxes[state.currentKey].items]
            }

            if (state.viewedInboxes.indexOf(props.key) === -1) {
                state.viewedInboxes.push(props.key)
                if (state.viewedInboxes.length > 3) {
                    const oldestKey = state.viewedInboxes.shift()
                    delete state.inboxes[oldestKey] // No need to check first. If the key is deleted, no errors are returned
                }
            }
        },
        SET_LOADING_START (state, key) {
            if (!(key in state.inboxes)) {
                this.commit('conversations/CREATE_INBOX_STATE', { key, props: {} })
            }

            // Pending represents the items that have newly been added
            state.inboxes[key].pending = []
            state.inboxes[key].$running = true
            state.inboxes[key].hasNext = false // We set to false while we load
            state.inboxes[key].index = state.inboxes[key].items.length // Index needs to be added here because of when calling NEXT

            // We show a loader only when there are no items
            state.inboxes[key].$loading = state.inboxes[key].items.length === 0
        },
        UPDATE_ITEMS (state, { identifier, targetId }) {
            if (!(identifier in state.inboxes)) return
            if (state.inboxes[identifier].pending.indexOf(targetId) === -1) {
                state.inboxes[identifier].pending.push(targetId)
            }

            if (state.inboxes[identifier].items.indexOf(targetId) > -1) return

            // We need to get the position of the previous item in the items list
            // If it is at the last, we add at the last
            // Otherwise, we insert the next one

            // So first, we get the position of the previous item
            let previousId = state.inboxes[identifier].pending[state.inboxes[identifier].pending.length - 2] // -2 because we already have added the current one
            if (previousId === undefined && state.inboxes[identifier].cursor) {
                // pending is empty on a next load, so we push at the end of the current list in that case
                previousId = state.inboxes[identifier].items[state.inboxes[identifier].items.length - 1]
            }

            const previousPos = state.inboxes[identifier].items.indexOf(previousId)
            if (state.inboxes[identifier].items[state.inboxes[identifier].items.length - 1] === previousId) {
                // It's at the end, so we just push
                state.inboxes[identifier].items.push(targetId)
            } else {
                // We insert
                state.inboxes[identifier].items.splice(previousPos + 1, 0, targetId)
                this.commit('conversations/ADD_IN_CURRENT_INBOX', { id: targetId, inbox: identifier, position: previousPos, effect: true })
            }

            if (state.inboxes[identifier].$loading) {
                state.inboxes[identifier].$loading = false
            }
        },
        SET_LOADING_END: (state, { key, limit }) => {
            // Loading ended
            if (!state.inboxes[key]) return
            if (state.inboxes[key].$loading) state.inboxes[key].$loading = false
            state.inboxes[key].$running = false

            state.inboxes[key].shallowItems = [...state.inboxes[key].items] // We need to create a copy to ensure it is not changed!

            state.inboxes[key].hasNext = state.inboxes[key].pending.length === limit
            state.inboxes[key].pending = []

            let cursor = null
            if (state.inboxes[key].hasNext) {
                const id = state.inboxes[key].items[state.inboxes[key].items.length - 1]
                cursor = state.items[id].cursor
            }
            state.inboxes[key].cursor = cursor
        },
        UPDATE_SHALLOW_ITEMS: (state, key) => {
            if (state.currentKey !== key) return
            if (!(state.currentKey in state.inboxes)) return
            state.inboxes[state.currentKey].shallowItems = [...state.inboxes[state.currentKey].items]
        },
        ADD_TAG: (state, { id, tag }) => {
            state.items[id]._tags.push(tag.id)
        },
        REMOVE_TAG: (state, { id, tag }) => {
            const index = state.items[id]._tags.findIndex(x => parseInt(x) === tag.id)
            if (index > -1) {
                state.items[id]._tags.splice(index, 1)
            }
        },
        ADD_IN_CURRENT_INBOX: (state, { id, inbox, position, effect = false }) => {
            if (id in state.inboxes[inbox].removeds) {
                // If it's present in the removing system, we stop the timeout and remove the state
                clearTimeout(state.inboxes[inbox].removeds[id])
                delete state.inboxes[inbox].removeds[id]
            }

            // We only add if it's not present
            if (state.inboxes[inbox].items.indexOf(id) === -1) {
                state.inboxes[inbox].items.splice(position, 0, id)
                if (effect) {
                    state.inboxes[inbox].news.push(id)
                    setTimeout(() => {
                        const pos = state.inboxes[inbox].news.indexOf(id)
                        if (pos > -1) {
                            state.inboxes[inbox].news.splice(pos, 1)
                        }
                    }, 600)
                }
            }

            /**
             * IMPORTANT : Here, do not copy the current.items array
             * Because the shallowItems handles incoming differently!
             */
            if (state.inboxes[inbox].shallowItems.indexOf(id) === -1) {
                state.inboxes[inbox].shallowItems.splice(position, 0, id)
            }
        },
        REMOVE_FROM_INBOX: (state, { id, inbox }) => {
            let duration = 0
            if (state.currentKey === inbox) {
                // We delay the deletion
                duration = 1000
            }

            state.inboxes[inbox].removeds[id] = setTimeout(() => {
                delete state.inboxes[inbox].removeds[id]
                const pos = state.inboxes[inbox].items.indexOf(id)
                if (pos > -1) {
                    state.inboxes[inbox].items.splice(pos, 1)
                }
            }, duration)
        },
        REMOVE_FROM_ALL_INBOXES: (state, id) => {
            for (const key in state.inboxes) {
                let pos = state.inboxes[key].items.indexOf(id)
                if (pos > -1) state.inboxes[key].items.splice(pos, 1)

                pos = state.inboxes[key].pending.indexOf(id)
                if (pos > -1) state.inboxes[key].pending.splice(pos, 1)

                pos = state.inboxes[key].shallowItems.indexOf(id)
                if (pos > -1) state.inboxes[key].shallowItems.splice(pos, 1)

                pos = state.inboxes[key].news.indexOf(id)
                if (pos > -1) state.inboxes[key].news.splice(pos, 1)

                if (id in state.inboxes[key].removeds) {
                    clearTimeout(state.inboxes[key].removeds[id])
                    delete state.inboxes[key].removeds[id]
                }
            }
        },
        VIEWING: (state, { agentId, conversationId }) => {
            if (!state.items[conversationId]) return // Conversation was not found, we stop

            state.viewers[agentId] = conversationId
            if (state.items[conversationId].viewers.indexOf(agentId) === -1) {
                // We avoid adding many times
                state.items[conversationId].viewers.push(agentId)
            }
        },
        UNVIEWING: (state, { agentId, conversationId }) => {
            if (!state.items[conversationId]) return // Conversation was not found, we stop

            const index = state.items[conversationId].viewers.indexOf(agentId)
            if (index > -1) {
                state.items[conversationId].viewers.splice(index, 1)
            }
            delete state.viewers[agentId]
        },
        SET_DRAFT_VALUE: (state, { editor, conversationId, body }) => {
            if (!state.items[conversationId]) return // Conversation was not found, we stop

            if (body) {
                state.items[conversationId].drafts[editor] = body
            } else {
                delete state.items[conversationId].drafts[editor]
            }
        }
    },
    actions: {
        ...defaults.actions,
        async compile (context, query) {
            if ('q' in query) return query.q

            const filter = []
            Object.keys(query).forEach(key => {
                if (key === 'order') return
                filter.push(`${key}:${query[key]}`)
            })

            return filter.join(' ')
        },
        async filter ({ state, commit, dispatch }, { query, primary = false }) {
            // We use the given filters, not the changed one, because the other parts have no clue we changed it.
            const key = generateKey(query)
            commit('UPDATE_SHALLOW_ITEMS', key) // This is needed for when we go back from a ticket, to match the listing

            if (state.currentKey !== key) { // We filter twice the same value, so we avoid it
                commit('SET_CURRENT', {
                    key,
                    search: await dispatch('compile', query),
                    order: query.order || 'priority',
                    query,
                    listing: true, // This is used to know if we check for changes in this inbox
                    primary
                })

                if (primary) dispatch('statistics/resetProgress', null, { root: true })
                else dispatch('statistics/disableProgress', null, { root: true })

                await dispatch('next')
            }
        },
        async next ({ state, getters, commit, dispatch }) {
            if (!getters.hasMore || getters.current.$running) return
            const key = state.currentKey // We do that to ensure we keep THAT key if it changes during the call to '/load'

            const params = {
                search: getters.current.search,
                order: getters.current.order,
                limit: LIMIT_VALUE,
                cursor: getters.current.cursor
            }

            // `limit` is set at LIMIT_VALUE only when loading the initial or the next items
            // Otherwise, if there are items already, and no cursor, it means a refresh
            // so we set the limit to the current items length

            if (!params.cursor && getters.current.items.length) {
                // We do +1 to force one more and set the proper value for `hasNext`
                params.limit = Math.max(getters.current.items.length, LIMIT_VALUE - 1) + 1 // Do not use .index here as it is defined after
            }

            await dispatch('load', { params, key })
        },
        async load ({ getters, commit }, { params, key = null }) {
            // Used here by next, but also by Contacts.js
            if (!key) {
                // Happens when loading one ticket (details)
                key = generateKey(params)
            }

            commit('SET_LOADING_START', key)
            try {
                await Api.stream(getters.endpointUrl(), {
                    params,
                    headers: {
                        'X-Content-Identifier': key
                    }
                })
            } catch (error) {
                Api.error(error, 'We were unable to load the tickets.')
            }
            commit('SET_LOADING_END', { key, limit: params.limit })

            const items = getters.getItems(key)
            if (!items) return []
            return Array.from(items)
        },
        async postAdd ({ commit, dispatch }, { id, identifier, server }) {
            // postAdd is also called on Fetch, hence the identifier.
            if (identifier) commit('UPDATE_ITEMS', { targetId: id, identifier })
            else if (server) { // No identifier, it means an "unrequested" entry
                dispatch('statistics/load', null, { root: true })
                await dispatch('detectCurrentChanges', { action: 'add', id })
            }
        },
        async postUpdate ({ commit, dispatch }, { id, identifier, server }) {
            if (identifier) commit('UPDATE_ITEMS', { targetId: id, identifier })
            else if (server) { // No identifier, it means an "unrequested" entry
                dispatch('statistics/load', null, { root: true })
                await dispatch('detectCurrentChanges', { action: 'update', id })
            }
        },
        async postDelete ({ commit, dispatch }, { id, document, server }) {
            commit('REMOVE_FROM_ALL_INBOXES', id)
            commit('contacts/REMOVE_CONVERSATION', { conversationId: id, contactId: document.contact_id }, { root: true })

            if (server) {
                commit('statistics/ADD_IN_PROGRESS', id, { root: true })
                dispatch('statistics/load', null, { root: true })
            }
        },
        async _fetch ({ commit }, struct) {
            if (struct.identifier) commit('UPDATE_ITEMS', { targetId: struct.target_id, identifier: struct.identifier })
        },
        async tag ({ state, getters }, { id, name }) {
            if (state.items[id].tags.findIndex(tag => tag.slug === name) === -1) {
                await Api.post(getters.endpointUrl({ instance: state.items[id], path: '/tags' }), { name })
            }
        },
        async untag ({ state, getters, commit }, { id, tag }) {
            if (state.items[id].tags.findIndex(t => t.slug === tag.slug) > -1) {
                commit('REMOVE_TAG', { id, tag })
                await Api.delete(getters.endpointUrl({ instance: state.items[id], path: `/tags/${tag.slug}` }))
            }
        },
        async setBulk ({ getters }, changes) {
            // /!\ This section should never trigger new loads
            // Because it is the same scenario for "postUpdate" events !
            // for instance: another agent closing all the current tickets, one by one
            // This won't happen here, but will still cause troubles.
            await Api.post(getters.endpointUrl({ path: '/bulk' }), changes)
        },
        updateDraft ({ commit }, { editor, conversationId, body }) {
            commit('SET_DRAFT_VALUE', { editor, conversationId, body })
        },
        async send ({ state, getters, commit, dispatch }, { id, editor, content, options }) {
            const instance = state.items[id]

            dispatch('incrementPendingUploads', null, { root: true })
            try {
                const response = await Api.post(getters.endpointUrl({ instance, path: `/${editor}` }), content, options)

                if ('generated_id' in response) {
                    dispatch('messages/updateEntryId', { newId: response.id, oldId: response.generated_id }, { root: true })
                }

                if (instance.drafts && instance.drafts[editor]) {
                    commit('SET_DRAFT_VALUE', { editor, conversationId: id, body: null })
                }
            } finally {
                dispatch('decrementPendingUploads', null, { root: true })
            }
        },
        async spam ({ state, getters }, { id, spam }) {
            const instance = state.items[id]
            try {
                await Api.post(getters.endpointUrl({ instance, path: '/spam' }), { block: spam })
            } catch (error) {
                Api.error(error, 'An error occurred while marking the message as spam.')
            }
        },
        async addRecipient ({ state, getters }, { id, email }) {
            await Api.post(getters.endpointUrl({ instance: state.items[id], path: '/recipients' }), { email })
        },
        async removeRecipient ({ state, getters }, { id, email }) {
            await Api.delete(getters.endpointUrl({ instance: state.items[id], path: `/recipients/${email}` }))
        },
        async matches ({ getters }, { inbox, document, id, index }) {
            // if it doesn't match, we don't need the position, so we can ignore the index
            // if it matches, and the index is -1, we need it

            if (document && inbox.query && index > -1) {
                let matches = true
                // We have a chance for a match (:
                const keys = Object.keys(inbox.query)
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i]
                    const val = inbox.query[key].toLowerCase()
                    if (key === 'from') {
                        if (val.indexOf('@') > -1) {
                            // Search by email, perfect match
                            if (document.contact.email.toLowerCase() !== val) {
                                matches = false
                                break
                            }
                        } else {
                            // Matches by name
                            if (document.contact.name.toLowerCase().indexOf(val === -1)) {
                                matches = false
                                break
                            }
                        }
                    } else if (key === 'tag') {
                        let found = false
                        for (let y = 0; y < document.tags.length; y++) {
                            if (document.tags[y].slug.indexOf(val) > -1 || document.tags[y].name.toLowerCase().indexOf(val) > -1) {
                                found = true
                                break
                            }
                        }
                        if (!found) {
                            matches = false
                            break
                        }
                    } else if (key === 'is') {
                        if (document.status !== val.toUpperCase()) {
                            matches = false
                            break
                        }
                    } else if (key === 'assigned') {
                        // Options are : me, me+none, none, email
                        if (val === 'me') {
                            if (!document.agent?.is_current_user) {
                                matches = false
                                break
                            }
                        } else if (val === 'me+none') {
                            if (!(!document.agent_id || document.agent?.is_current_user)) {
                                matches = false
                                break
                            }
                        } else if (val === 'none') {
                            if (document.agent_id) {
                                matches = false
                                break
                            }
                        } else if (val.indexOf('@') > -1) {
                            if (document.agent?.email !== val) {
                                matches = false
                                break
                            }
                        }
                    } else if (key === 'exclude') {
                        if (val.replace(' ', '').split(',').indexOf(id + '') === -1) {
                            matches = false
                            break
                        }
                    } else if (key === 'order') {
                        continue
                    }
                }

                if (!matches || index > -1) {
                    return [matches, index]
                }
            }

            const response = await DelayedResponse.load(id, {
                search: inbox.search,
                order: inbox.order,
                limit: getters.filtered.length
            })

            if (!response) return [false, -1]
            return [
                response.matches,
                response.position
            ]
        },
        async detectCurrentChanges ({ state, getters, commit, dispatch }, { action, id }) {
            /**
             * Detects the change made to decide if that ticket {id} should remain in the list or not
             *
             * Loops over all the currently open inboxes
             *
             * Here's a run down of the actions
             *
             * If action is add
             *      If the document DOES NOT matches the search
             *          We can ignore
             *      Else
             *          We need to do a search for the index
             * Else if action is update
             *      If the document matches the search
             *          if the document is not present in the listing
             *              We need to do a search
             *          if the document is present in the listing
             *              We do nothing
             *      else if the document does not matches the searches
             *          if the document is present in the listing
             *              we remove it
             *          else:
             *              we do nothing
             * else if action is delete
             *      if the document matches the search
             *          if the document is in the listing
             *              We remove
             *          else
             *              We do nothing
             *      else if the document doesn't match the listing
             *          we do nothing
             */

            // We process all the inboxes, but we first start with the current one !
            let keys = []
            if (state.currentKey) {
                keys.push(state.currentKey)
                keys.push(...Object.keys(state.inboxes).filter(key => key !== state.currentKey))
            } else {
                keys = Object.keys(state.inboxes)
            }

            keys.forEach(async key => {
                const currentInbox = state.inboxes[key]

                // We create inboxes for all listing, such as last_conversations.
                // We need to ignore them here!
                if (!currentInbox.listing) return

                let currentPosition = -1
                if (currentInbox.items) {
                    currentPosition = currentInbox.items.indexOf(id)
                }

                const [matches, position] = await dispatch('matches', { inbox: currentInbox, document: getters.get(id), index: currentPosition, id })

                let documentAction = null
                if (action === 'add') {
                    if (matches) {
                        // Add to position, we need the new position !
                        documentAction = 'add'
                    }
                } else if (action === 'update') {
                    if (matches) {
                        if (currentPosition === -1) {
                            // Add at new position
                            documentAction = 'add'
                        }
                    } else if (currentPosition > -1) {
                        // We remove it
                        documentAction = 'remove'
                    }
                } else if (action === 'delete' && currentPosition > -1) {
                    documentAction = 'remove'
                }

                if (documentAction === 'add') {
                    commit('ADD_IN_CURRENT_INBOX', { id, inbox: key, position, effect: key === state.currentKey })

                    if (currentInbox.primary) {
                        dispatch('statistics/decreaseProgress', id, { root: true })
                    }
                } else if (documentAction === 'remove') {
                    commit('REMOVE_FROM_INBOX', { id, inbox: key })

                    if (currentInbox.primary) {
                        dispatch('statistics/increaseProgress', id, { root: true })
                    }
                }
            })
        },
        enter ({ state, commit }, { agentId, conversationId }) {
            /** Triggered when an agent enters the conversation */
            if (state.viewers[agentId] === conversationId) return // Same events, we ignore

            if (state.viewers[agentId]) {
                // We remove the previous viewing state
                commit('UNVIEWING', { agentId, conversationId: state.viewers[agentId] })
            }

            commit('VIEWING', { agentId, conversationId })
        },
        left ({ state, commit }, { agentId, conversationId }) {
            /** Trigerred when an agent leaves the conversation */

            if (!state.viewers[agentId]) return // Nothing to do, he is not registered

            if (state.viewers[agentId] === conversationId) {
                // We only unview if it's the same, in case the "enter" event arrived before the left one
                // (when the user goes next in a conversation, this might happen)
                commit('UNVIEWING', { agentId, conversationId })
            }
        }
    }
}

class DelayedResponse {
    /**
     * This class adds the request for a match to a pending timeout
     * Once the timeout ends, the request is sent, with the list of IDs to match
     * If a new request is added in the meantime, the timeout is reset
     */

    static instance = null
    static getInstance () {
        if (!this.instance) {
            this.instance = new this()
        }

        return this.instance
    }

    static async load (id, params = {}) {
        return await this.getInstance().process(id, params)
    }

    queries = {}
    callbacks = {}
    timers = {}

    process (id, params) {
        /**
         * Adds the given ID to the already existing {params} query
         * (Re)start the timer, and wait for the request to complete
         * Then, return the value for the given ID
         */
        const strParams = JSON.stringify(params)
        if (strParams in this.timers) {
            // We clear a timeout that will be restarted soon after
            clearTimeout(this.timers[strParams])
            delete this.timers[strParams]
        }

        // We create a query based on the request
        if (!(strParams in this.queries)) {
            this.queries[strParams] = {
                ...params,
                ids: []
            }

            this.callbacks[strParams] = {}
        }

        if (this.queries[strParams].ids.indexOf(id) === -1) {
            this.queries[strParams].ids.push(id)
        }

        return new Promise((resolve, reject) => {
            // ...and add the id
            this.callbacks[strParams][id.toString()] = { resolve, reject }
            this.timers[strParams] = setTimeout(() => {
                delete this.timers[strParams]

                // We need to copy and delete to avoid concurrency issues
                const queries = Object.keys(this.queries[strParams]).reduce((acc, key) => {
                    acc[key] = this.queries[strParams][key]
                    return acc
                }, {})

                delete this.queries[strParams]

                const callbacks = Object.keys(this.callbacks[strParams]).reduce((acc, key) => {
                    acc[key] = this.callbacks[strParams][key]
                    return acc
                }, {})
                delete this.callbacks[strParams]

                this.send(queries, callbacks)
            }, 150)
        })
    }

    async send (params, callbacks) {
        try {
            const result = await Api.get('/conversations/matches', { params })

            /**
             * This is where the magic shit happens
             * If we return blindly here, we will add them in the incorrect order when there are more than one result
             * That makes sense: if I get pos 3 then 2 then 5, the position when adding 5 will have changed from now
             * (since I've added 2 and 3 before !)
             * So I need to order the callback in the order of their position!
             */
            Object.keys(result).sort((x, y) => result[x].position - result[y].position).forEach(id => {
                callbacks[id].resolve(result[id])
                delete callbacks[id]
            })

            Object.entries(callbacks).forEach(([id, callback]) => {
                callback.reject(new Error(`Unprocessed id for matching response ${id}`))
            })
        } catch (e) {
            Object.values(callbacks).forEach(callback => {
                callback.reject(e)
            })
        }
    }
}

export default conversations
