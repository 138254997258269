import Vue, { configureCompat } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/stores/store'
import Environment from '@/resources/Environment'
import VueCommands from '@/plugins/VueCommands/plugin'
import TitleMixin from '@/mixins/Title'
import RolesMixin from '@/mixins/Roles'
import EventsMixin from '@/plugins/Events'
import AuthHandler from '@/plugins/AuthHandler'
import Perf from '@/plugins/Perf'
import axios from 'axios'

import Modal from '@/components/Modal'
import Avatar from '@/components/Avatar'
import Keyboard from '@/components/Keyboard'
import Storage from '@/resources/Storage'
import WebSocket from '@/resources/WebSocket'

import { DateTime } from 'luxon'

import '@/assets/css/app.css'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away-subtle.css'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

configureCompat({ MODE: 3 })
Vue.config.productionTip = false

tippy.setDefaultProps({
    animation: 'shift-away-subtle',
    inertia: true,
    theme: 'light',
    placement: 'top'
})

axios.interceptors.request.use(config => {
    // Do something before request is sent
    if (AuthHandler.token !== null && !('Authorization' in config.headers)) {
        config.headers.Authorization = 'Bearer ' + AuthHandler.token
    }
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

axios.interceptors.response.use(response => (response), error => {
    if (error?.response?.status === 401) {
        store.dispatch('disconnected')
    }

    /*
    try {
        Promise.reject(error)
    } catch (e) {
        let message = 'A fatal error occurred on our end.'
        if (error.response?.data?.errors) {
            const key = Object.keys(error.response.data.errors)[0]
            message = key + ': ' + error.response.data.errors[key]
        } else if (error.response?.data?.error) {
            message = error.response.data.error
        }

        // alert('NOTIFICATION CENTER (todo)\nFATAL ERROR: ' + message)
        console.warn('NOTIFICATION CENTER (todo)\nFATAL ERROR: ' + message)
    }
    */
    throw error
})

Environment.getAPIUrl().then((url) => {
    axios.defaults.baseURL = url
    WebSocket.setUrl(url)

    const app = Vue.createApp(App)
    app.config.compilerOptions.whitespace = 'preserve'
    app.config.unwrapInjectedRef = true
    app.use(router)
    app.use(store)
    app.use(EventsMixin)
    if (Environment.isDev()) app.use(Perf)
    app.use(VueCommands)
    app.use(Toast, {
        transition: 'Vue-Toastification__fade',
        maxToasts: 20,
        newestOnTop: true,
        position: 'bottom-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false
    })

    app.mixin(TitleMixin)
    app.mixin(RolesMixin)

    app.config.globalProperties.$filters = {
        money: (value, currency) => {
            if (typeof value !== 'number') { return value }
            value = parseFloat(value)
            return value.toLocaleString('en-US', { style: 'currency', currency: currency || 'USD', maximumFractionDigits: 2 }).replace('.00', '')
        },
        datetime: (date, format) => {
            if (!date) {
                return null
            }
            if (!format) {
                format = 'yyyy/MM/dd HH:mm:ss'
            }

            let dt = null
            if (isNaN(date)) {
                dt = DateTime.fromISO(date, { zone: 'utc', locale: 'en' })
            } else {
                console.warn('Missed data for datetime', date)
                dt = DateTime.fromMillis(parseInt(date), { zone: 'utc', locale: 'en' })
            }
            return dt.toLocal().toFormat(format)
        },
        isoformat: (date) => date ? DateTime.fromISO(date, { zone: 'utc' }).toLocal().toISO() : null,
        pluralize: (quantity, single, plural = null) => {
            if (quantity === 1) return `${quantity} ${single}`

            if (!plural) plural = single + 's'

            return `${quantity} ${plural}`
        },
        relative: (date) => date ? DateTime.fromISO(date, { zone: 'utc', locale: 'en' }).toLocal().toRelative() : null,
        relativeSnooze: (date) => {
            if (!date) {
                return null
            }

            let lxdate = null
            if (isNaN(date)) {
                lxdate = DateTime.fromISO(date, { zone: 'utc', locale: 'en' }).toLocal()
            } else {
                lxdate = DateTime.fromMillis(parseInt(date), { zone: 'utc', locale: 'en' }).toLocal()
            }

            const today = DateTime.utc({ zone: 'utc' })
            if (lxdate.year === today.year && lxdate.month === today.month && lxdate.day === today.day) {
                return lxdate.toFormat("'today at ' t")
            }

            const tomorrow = today.plus({ days: 1 })
            if (lxdate.year === tomorrow.year && lxdate.month === tomorrow.month && lxdate.day === tomorrow.day) {
                return lxdate.toFormat("'tomorrow at ' t")
            }

            if (today.year === lxdate.year) {
                return lxdate.toFormat("EEEE, MMM dd ' at ' t")
            }

            return lxdate.toFormat("DDDD ' at ' t")
        },
        capitalize: (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }

    app.component('Relative', {
        template: '<time :data-title="datetimeFormat(dt, format)" :datetime="dt.toISO()" @mouseenter="renderTippy">{{ relative }}</time>',
        props: {
            date: {
                required: true
            },
            format: {
                required: false,
                type: String,
                default: "EEEE, dd MMM yyyy ' at ' t"
            },
            lowerCase: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                interval: null,
                currentTs: Date.now()
            }
        },
        created () {
            this.interval = setInterval(() => {
                this.currentTs = Date.now()
            }, 1000)
        },
        beforeUnmount () {
            clearInterval(this.interval)
        },
        computed: {
            dt () {
                if (!this.date) return
                return DateTime.fromISO(this.date, { zone: 'utc', locale: 'en' }).toLocal()
            },
            _relative () {
                if (!this.date) {
                    return 'N/A'
                }

                const diffSeconds = parseInt((this.dt.toMillis() - this.currentTs) / 1000)
                const isFuture = diffSeconds > 0
                const absSeconds = Math.abs(diffSeconds)

                if (absSeconds < 60) {
                    return isFuture ? 'In less than a minute' : 'Less than a minute ago'
                } else if (absSeconds < 120) {
                    return isFuture ? 'In a minute' : 'A minute ago'
                } else if (absSeconds < 3600) {
                    const minutes = Math.floor(absSeconds / 60)
                    return isFuture ? `In ${minutes} minutes` : `${minutes} minutes ago`
                } else if (absSeconds < 7200) {
                    return isFuture ? 'In an hour' : 'An hour ago'
                } else if (absSeconds < 86400) {
                    const hours = Math.floor(absSeconds / 3600)
                    return isFuture ? `In ${hours} hours` : `${hours} hours ago`
                } else if (absSeconds <= 2678400) { // 31 days
                    // In this case, we need to have a relative time based on the current timezone
                    const deltaDt = DateTime.now().startOf('day').diff(this.dt.startOf('day'), ['days'])
                    if (deltaDt.values.days === 1) {
                        return isFuture ? 'Today' : 'Yesterday'
                    }
                    const days = Math.abs(deltaDt.values.days)
                    return isFuture ? `In ${days} days` : `${days} days ago`
                }

                return this.dt.toFormat(this.format)
            },
            relative () {
                return this.lowerCase ? this._relative.toLowerCase() : this._relative
            }
        },
        methods: {
            renderTippy () {
                if (this.$el._tippy) {
                    this.$el._tippy.destroy()
                }

                tippy(this.$el, {
                    content: this.$el.getAttribute('data-title'),
                    appendTo: () => document.body,
                    showOnCreate: true,
                    trigger: 'mouseenter',
                    onHidden (instance) {
                        instance.destroy()
                    }
                })
            },
            datetimeFormat (dt) {
                if (!dt) return null

                if (this.format !== "EEEE, dd MMM yyyy ' at ' t") {
                    return dt.toFormat(this.format)
                }

                // Test if the datetime is today
                const today = DateTime.now().startOf('day')
                if (dt.hasSame(today, 'day')) {
                    return dt.toFormat("'Today at ' t")
                }

                // Test if the datetime is yesterday
                const yesterday = today.minus({ days: 1 })
                if (dt.hasSame(yesterday, 'day')) {
                    return dt.toFormat("'Yesterday at ' t")
                }

                // Test if the datetime is tomorrow
                const tomorrow = today.plus({ days: 1 })
                if (dt.hasSame(tomorrow, 'day')) {
                    return dt.toFormat("'Tomorrow at ' t")
                }

                // Test if same year
                if (dt.hasSame(today, 'year')) {
                    return dt.toFormat("EEEE, dd MMM ' at ' t")
                }

                return dt.toFormat(this.format)
            }
        }
    })

    app.component('modal', Modal)
    app.component('avatar', Avatar)
    app.component('keyboard', Keyboard)

    app.directive('dev', {
        mounted (el, binding, vnode) {
            if (Environment.getEnv() === Environment.PROD && Storage.get('beta') !== '1') {
                vnode.el.parentNode.removeChild(vnode.el)
            }
        }
    })

    app.directive('tooltip', {
        mounted (el, { value, modifiers }) {
            if (!value) {
                value = el.getAttribute('title')
            }

            tippy(el, {
                content: value,
                placement: modifiers.position || 'top',
                appendTo: () => document.body,
                showOnCreate: false,
                trigger: 'mouseenter',
                allowHTML: true
            })
        },
        unmounted (el) {
            if (el._tippy) {
                el._tippy.destroy()
            }
        }
    })

    app.mount('#app')
})

// Extending console:
const getCircularReplacer = () => {
    const seen = new WeakSet()
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) return
            seen.add(value)
        }
        return value
    }
}
console.dump = (x, trace = true) => {
    if (trace) {
        console.groupCollapsed('Dump trace')
        console.trace()
        console.groupEnd()
    }

    if (x instanceof Object) {
        console.log(JSON.stringify(x, getCircularReplacer(), 4))
    } else {
        console.log(x)
    }
}

window.print = () => { console.warn('Did you mean "console.log" ?...') }
