<template>
    <div class="container" style="max-width:280px;">
        <div v-if="kind === 'refresh'">
            <strong>New version available</strong>
            <p class="u--subtle u--mt8">An improved version of Fernand is available. Please refresh now to upgrade.</p>
        </div>
        <div v-if="kind === 'disconnected'">
            <strong>You were disconnected</strong>
            <p class="u--subtle u--mt8">Something went wrong. Please refresh your browser to reconnect.</p>
        </div>
        <a href="javascript:;" title="Refresh Fernand" @click="refresh" class="button button--secondary button--small">Refresh</a>
    </div>
</template>

<script>
export default {
    props: {
        kind: {
            type: String,
            required: true,
            default: null
        }
    },
    methods: {
        refresh () {
            window.location.reload(true)
        }
    }
}
</script>
