import AuthHandler from '@/plugins/AuthHandler'
import defaults from './utils'
import Api from '@/resources/Api'
import { DateTime } from 'luxon'

const baseState = () => ({
    items: {}
})

const organizations = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        active: (state) => {
            return state.items[Object.keys(state.items)[0]]
        },
        isTrial: (state, getters) => {
            return !getters.active?.is_premium
        },
        remainingDays: (state, getters) => {
            if (getters.isTrial && getters.active?.trial_ends_at) {
                return parseInt(DateTime.fromISO(getters.active.trial_ends_at, { zone: 'utc', locale: 'en' }).diffNow('days').values.days)
            }
            return null
        },
        showOnboarding: (state, getters) => {
            const active = getters.active
            if (!active) return true

            return Object.keys(active.onboarding).length < 7
        },
        openingHours: (state, getters) => getters.active.hours
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        }
    },
    actions: {
        ...defaults.actions,
        async setStepState ({ getters }, { step, state }) {
            return await Api.post(`organization/onboarding/${step}/${state}`)
        },
        async updateHours ({ getters }, params) {
            return await Api.post('organization/hours', params)
        },
        async postAdd (context, { document }) {
            AuthHandler.setName(document.name)
        },
        async postUpdate (context, { document }) {
            AuthHandler.setName(document.name)
        }
    }
}

export default organizations
